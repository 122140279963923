
import './App.css';
import React, { useState, useEffect, useCallback } from 'react';
import { Pressable, ImageBackground, Platform, Share, SafeAreaView, ScrollView, Text, Linking, View, Image, StyleSheet, FlatList } from 'react-native';
import { signInAnonymously } from "firebase/auth";

import { storage, appCheckInstance, auth } from './firebaseConfig';
import { ref, getDownloadURL } from 'firebase/storage';
import { getToken } from "firebase/app-check";


const loginUser = async () => {
  try {
    await signInAnonymously(auth);
    console.log('User logged in anonymously');
  } catch (error) {
    console.error('Error with anonymous login:', error);
  }
};

const appCheckToken = await getToken(appCheckInstance, true);

const fetchImageUrls = async (setImageUrls) => {
  //const appCheckToken = await getToken(appCheckInstance, true);
  const indexPath = process.env.REACT_APP_FIREBASE_STORAGE_INDEX_PATH;
  const indexRef = ref(storage, indexPath);
  const indexUrl = await getDownloadURL(indexRef, { customHeaders: { "X-Firebase-AppCheck": appCheckToken.token } });
  const indexResponse = await fetch(indexUrl);
  const indexData = await indexResponse.json();
  const urls = {};

  for (const filePath of indexData.files) {
    const vendorName = filePath.split('/')[4].toLowerCase();
    const imageRef = ref(storage, `${process.env.REACT_APP_FIREBASE_STORAGE_CAT_ICONS_PATH}/${vendorName}-${process.env.REACT_APP_CATEGORY}-icon.png`);
    urls[vendorName] = await getDownloadURL(imageRef, { customHeaders: { "X-Firebase-AppCheck": appCheckToken.token } });
  }

  setImageUrls(urls);
};

// Move the map objects outside of the component
/*
const fetchImageUrls = async (setImageUrls) => {
  const indexPath = process.env.REACT_APP_FIREBASE_STORAGE_INDEX_PATH;
  const indexRef = ref(storage, indexPath);
  const indexUrl = await getDownloadURL(indexRef);
  const indexResponse = await fetch(indexUrl);
  const indexData = await indexResponse.json();
  const urls = {};

  for (const filePath of indexData.files) {
    const vendorName = filePath.split('/')[4].toLowerCase();
    const imageRef = ref(storage, `${process.env.REACT_APP_FIREBASE_STORAGE_CAT_ICONS_PATH}/${vendorName}-${process.env.REACT_APP_CATEGORY}-icon.png`);
    urls[vendorName] = await getDownloadURL(imageRef);
  }

  setImageUrls(urls);
};
*/
const fetchIndexAndProducts = async (setVendors) => {
  try {
    //const appCheckToken = await getToken(appCheckInstance, true);
    const indexPath = process.env.REACT_APP_FIREBASE_STORAGE_INDEX_PATH;
    const indexRef = ref(storage, indexPath);
    const indexUrl = await getDownloadURL(indexRef, { customHeaders: { "X-Firebase-AppCheck": appCheckToken.token } } );
    const indexResponse = await fetch(indexUrl);
    const indexData = await indexResponse.json();

    const vendorPromises = indexData.files.map(async filePath => {
      try {
        const fileRef = ref(storage, filePath);
        const fileUrl = await getDownloadURL(fileRef, { customHeaders: { "X-Firebase-AppCheck": appCheckToken.token } });
        const fileResponse = await fetch(fileUrl);
        const products = await fileResponse.json();
        if (products.length === 0) {
          console.log(`${filePath} is empty.`);
          return null;
        }

        const vendorName = filePath.split('/')[4];
        return { vendorName, products: products.map(product => ({ ...product, vendorName })) };
      } catch (error) {
        console.error(`Error fetching products for ${filePath}:`, error);
        return null;
      }
    });

    const vendorsData = (await Promise.all(vendorPromises)).filter(vendor => vendor !== null);
    setVendors(vendorsData);
  } catch (error) {
    console.error('Fetching index file failed:', error);
  }
};

const fetchTodayNewProducts = async (setTodayNewProducts) => {
  try {
    //const appCheckToken = await getToken(appCheckInstance, true);

    const indexPath = process.env.REACT_APP_FIREBASE_STORAGE_DAYNEW_INDEX_PATH;
    const indexRef = ref(storage, indexPath);
    const indexUrl = await getDownloadURL(indexRef, { customHeaders: { "X-Firebase-AppCheck": appCheckToken.token } });
    const indexResponse = await fetch(indexUrl);
    const indexData = await indexResponse.json();

    const vendorPromises = indexData.files.map(async filePath => {
      try {
        const fileRef = ref(storage, filePath);
        const fileUrl = await getDownloadURL(fileRef, { customHeaders: { "X-Firebase-AppCheck": appCheckToken.token } });
        const fileResponse = await fetch(fileUrl);
        const products = await fileResponse.json();
        if (products.length === 0) {
          console.log(`${filePath} is empty.`);
          return null;
        }

        const vendorName = filePath.split('/')[4];
        return { vendorName, products: products.map(product => ({ ...product, vendorName })) };
      } catch (error) {
        console.error(`Error fetching today's new products for ${filePath}:`, error);
        return null;
      }
    });

    const todayNewProductsData = (await Promise.all(vendorPromises)).filter(vendor => vendor !== null);
    setTodayNewProducts(todayNewProductsData);
  } catch (error) {
    console.error('Fetching today\'s new products index file failed:', error);
  }
};

const App = () => {
  const [vendors, setVendors] = useState([]);
  const [imageUrls, setImageUrls] = useState({});
  const [todayNewProducts, setTodayNewProducts] = useState([]);
  const [allDataFetched, setAllDataFetched] = useState(false);

  useEffect(() => {
    loginUser();
  }, []);

  useEffect(() => {
    fetchImageUrls(setImageUrls);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchIndexAndProducts(setVendors);
      await fetchTodayNewProducts(setTodayNewProducts);
      setAllDataFetched(true);
    };

    fetchData();
  }, []);

  const markTodayNewProducts = useCallback(() => {
    const markedVendors = vendors.map(vendor => {
      const todayNewVendor = todayNewProducts.find(newVendor => newVendor.vendorName === vendor.vendorName);
      if (todayNewVendor) {
        const todayNewProductNames = new Set(todayNewVendor.products.map(product => product['Product Name']));
        const products = vendor.products.map(product => ({
          ...product,
          isNewToday: todayNewProductNames.has(product['Product Name']),
        }));
        return { ...vendor, products };
      }
      return vendor;
    });

    markedVendors.forEach(vendor => {
      vendor.products.sort((a, b) => b.isNewToday - a.isNewToday);
    });

    setVendors(markedVendors);
  }, [vendors, todayNewProducts]);

  useEffect(() => {
    if (allDataFetched) {
      markTodayNewProducts();
    }
  }, [allDataFetched, markTodayNewProducts]);

  const renderProduct = ({ item }) => {
    const thumbnailUri = imageUrls[item.vendorName.toLowerCase()];
    return (
      <Pressable onPress={() => item['Product Url'] && Linking.openURL(item['Product Url'])} style={styles.card}>
        <Image source={{ uri: thumbnailUri }} style={styles.thumbnail} />
        <View style={styles.textContainer}>
          <Text style={styles.productName} numberOfLines={2}>{item['Product Name']}</Text>
          <Text style={styles.productPrice}>Price: {item['Product Price']}</Text>
          {item['Product Discount'] ? <Text style={styles.productDiscount}>Discount: {item['Product Discount']}</Text> : null}
          {item.isNewToday ? <Text style={styles.newTodayTag}>New Today</Text> : null}
        </View>
      </Pressable>
    );
  };

  const Header = () => {
// Doing this instead of importing with static background pic
    let backgroundImage;
    try {
      backgroundImage = require(`./${process.env.REACT_APP_CATEGORY_BACKGROUND_PATH}`);
    } catch (error) {
      console.error('Error loading background image:', error);
      backgroundImage = null; // Fallback in case the image can't be loaded
    }

    let nupeacockLogoImage;
    try {
      nupeacockLogoImage = require(`./${process.env.REACT_APP_NUPEACOCK_LOGO_PATH}`);
    } catch (error) {
      console.error('Error loading nupeacock logo image:', error);
      nupeacockLogoImage = null; // Fallback in case the image can't be loaded
    }

    let nupeacockTwitterLogoImage;
    try {
      nupeacockTwitterLogoImage = require(`./${process.env.REACT_APP_NUPEACOCK_TWITTER_LOGO_PATH}`);
    } catch (error) {
      console.error('Error loading twitter logo image:', error);
      nupeacockTwitterLogoImage = null; // Fallback in case the image can't be loaded
    }

    let nupeacockFacebookLogoImage;
    try {
      nupeacockFacebookLogoImage = require(`./${process.env.REACT_APP_NUPEACOCK_FACEBOOK_LOGO_PATH}`);
    } catch (error) {
      console.error('Error loading facebook logo image:', error);
      nupeacockFacebookLogoImage = null; // Fallback in case the image can't be loaded
    }

    let nupeacockInstagramLogoImage;
    try {
      nupeacockInstagramLogoImage = require(`./${process.env.REACT_APP_NUPEACOCK_INSTAGRAM_LOGO_PATH}`);
    } catch (error) {
      console.error('Error loading instagram logo image:', error);
      nupeacockInstagramLogoImage = null; // Fallback in case the image can't be loaded
    }

    let nupeacockSocialshareLogoImage;
    try {
      nupeacockSocialshareLogoImage = require(`./${process.env.REACT_APP_NUPEACOCK_SOCIALSSHARE_LOGO_PATH}`);
    } catch (error) {
      console.error('Error loading socialshare logo image:', error);
      nupeacockSocialshareLogoImage = null; // Fallback in case the image can't be loaded
    }

    let nupeacockEmailLogoImage;
    try {
      nupeacockEmailLogoImage = require(`./${process.env.REACT_APP_NUPEACOCK_EMAIL_LOGO_PATH}`);
    } catch (error) {
      console.error('Error loading email logo image:', error);
      nupeacockEmailLogoImage = null; // Fallback in case the image can't be loaded
    }


    return (
      <ImageBackground source={backgroundImage} style={styles.header} resizeMode="cover">

        <View style={styles.headerContent}>
          <View style={styles.socialIcons}>
            <Pressable onPress={() => Linking.openURL('https://nupeacock.com')}>
              <Image source={nupeacockLogoImage} style={styles.icon} />
            </Pressable>
          </View>
          <View style={styles.headerText}>
            <Text style={styles.title}>{process.env.REACT_APP_SITE_NAME}</Text>
            <Text style={styles.subtitle}>{process.env.REACT_APP_SITE_TEXT}</Text>
          </View>
          <View style={styles.socialIcons}>
            <Pressable onPress={() => Linking.openURL(process.env.REACT_APP_TWITTER_URL)}>
              <Image source={nupeacockTwitterLogoImage} style={styles.icon} />
            </Pressable>
            <Pressable onPress={() => Linking.openURL(process.env.REACT_APP_INSTAGRAM_URL)}>
              <Image source={nupeacockInstagramLogoImage} style={styles.icon} />
            </Pressable>
            <Pressable onPress={() => Linking.openURL(process.env.REACT_APP_FACEBOOK_URL)}>
              <Image source={nupeacockFacebookLogoImage} style={styles.icon} />
            </Pressable>
            <Pressable onPress={() => Linking.openURL('mailto:crengana@nupeacock.com')}>
              <Image source={nupeacockEmailLogoImage} style={styles.icon} />
            </Pressable>
            <Pressable onPress={sharePage}>
              <Image source={nupeacockSocialshareLogoImage} style={styles.icon} />
            </Pressable>
          </View>
        </View>
      </ImageBackground>
    );
  };

  const sharePage = () => {
    const shareData = {
      title: process.env.REACT_APP_SITE_TITLE,
      text: process.env.REACT_APP_SITE_TEXT,
      url: process.env.REACT_APP_SITE_URL,
    };
    if (Platform.OS === 'web') {
      if (navigator.share) {
        navigator.share(shareData)
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing:', error));
      } else {
        console.log('Web Share API not supported.');
      }
    } else {
      Share.share(shareData)
        .then((result) => {
          if (result.action === Share.sharedAction) {
            console.log('Share was successful');
          }
        })
        .catch((error) => console.log('Error sharing:', error));
    }
  };

  return (
    <SafeAreaView>
      <ScrollView>
        <Header />
        <FlatList
          data={vendors}
          renderItem={({ item, index }) => (
            <View key={`${index}-${item.vendorName}`}>
              <Text>{item.vendorName}</Text>
              <FlatList
                data={item.products}
                renderItem={renderProduct}
                keyExtractor={(item, idx) => `${index}-${idx}`}
                horizontal={true}
                showsHorizontalScrollIndicator={true}
                contentContainerStyle={styles.listContainer}
              />
            </View>
          )}
        />
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({

  header: {

    width: '100%',
    height: 400, // Adjust height as needed
    //flexDirection: 'row',
    // alignItems: 'center',
    // justifyContent: 'space-between', // Ensures logo is on the left and social icons on the right
    //justifyContent: 'flex-start',
    justifyContent: 'center',
    padding: 10,
  },
  headerContent: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap', // Allows wrapping on mobile
  },
  logo: {
      height: 60,
      //resizeMode: 'contain',
      flexDirection: 'row',
      justifyContent: 'flex-start',
  },
  icon: {
      height: 30,
      width: 30,
      marginLeft: 10,
      borderRadius: 25,
  },
  socialIcons: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginBottom: 200,
  },

  headerText: {
    flex: 1,
    //marginLeft: 15, // Adjust as needed
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 64, // Adjust to desired size
    color: 'fuchsia',
    fontWeight: 'bold',
    textShadow: '1px 1px 5px rgba(0, 0, 0, 0.75)',
    //textShadowColor: 'rgba(0, 0, 0, 0.75)',
    //textShadowOffset: { width: -1, height: 1 },
    //textShadowRadius: 10,
    textAlign: 'center',
  },
  subtitle: {
    //fontSize: 32, // Adjust to desired size
    fontSize: 24, // Smaller size for mobile, can adjust to 32 for desktop
    color: 'limegreen',
    marginTop: 5,
    //textShadowColor: 'rgba(0, 0, 0, 0.75)',
    //textShadowOffset: { width: -1, height: 1 },
    //textShadowRadius: 10,
    textShadow: '1px 1px 5px rgba(0, 0, 0, 0.75)',
    textAlign: 'center',
  },

  listContainer: {
    paddingHorizontal: 20,
    minWidth: 1000,
  },
  
  card: {
    width: 120, // Further reduced width
    marginRight: 20,
    borderRadius: 10,
    overflow: 'hidden',
    backgroundColor: '#fff',
    elevation: 3,
    boxShadow: '1px 1px 5px rgba(51, 51, 51, 0.3)',
    // shadowOffset: { width: 1, height: 1 },
    // shadowColor: "#333",
    //shadowOpacity: 0.3,
    // shadowRadius: 2,
  },
  thumbnail: {
    width: '100%',
    height: 100, // Reduced height
  },
  textContainer: {
    padding: 5,
  },
  productName: {
    fontSize: 12, // Adjusted for smaller card size
    fontWeight: 'bold',
    textAlign: 'center',
  },
  productPrice: {
    fontSize: 12,
    color: 'green',
    textAlign: 'center',
  },
  productDiscount: {
    fontSize: 11,
    color: 'red',
    textAlign: 'center',
  },
  newTodayTag: {
    fontSize: 14,
    color: 'green',
    fontWeight: 'bold',
  }


});

export default App;
